export var labels={    
    label_email:{
        en:"Email.",
        bn:"ইমেল"
    },
    label_password:{
        en:"Password.",
        bn:"পাসওয়ার্ড"
    },
    msg_email_require:{
        en:"Email Requied.",
        bn:"ইমেল অবশ্যক"
    },
}